.dynamic-blog {
  max-width: var(--max-width);
  margin: 0 auto;

}

.dynamic-blog .banner {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.dynamic-blog .info {
  margin-top: 2rem;
  padding: 1rem;
}

.dynamic-blog .title {
  font-size: 2rem;
  color: var(--orange);
  text-transform: uppercase;
}

.dynamic-blog .author,
.dynamic-blog .description {
  font-size: 1.5rem;
  color: rgb(152, 152, 152);
}

.dynamic-blog p {
  line-height: 30px;
  max-width: 900px;
}