
.services main {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  margin-top: 4rem;
}

.services main aside {
  display: flex;
  gap: 30px;
  margin-top: 2rem;
}

.services main div {
  width: 50%;
}

.services main img {
  width: 100%;
}

.services main .title {
  font-size: 3rem;
  margin-bottom: 1rem;
}




@media (max-width: 650px) {
  .services main aside {
    flex-direction: column;
  }

  .services main div {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .services main .title{
    font-size: 1.5rem;
  }
}