.navbar {
  /* background: var(--yellow); */
  background: black;
  box-shadow: 10px 0 20px rgb(194, 194, 194);
}

.mobile-nav {
  display: none;
}

.navbar .logo {
  width: 150px;
}

.navbar .navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  border-bottom: 1px solid rgb(192, 192, 192);
  color: grey;
}

.navbar .navbar-top aside {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar .navbar-bottom {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .navbar-bottom ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar .navbar-bottom a {
  text-decoration: none;
  color: inherit;
}

.navbar .navbar-bottom ul li {
  cursor: pointer;
  transition: 0.3s ease;
}

.navbar .navbar-bottom ul li:hover {
  color: var(--blue);
}








@media (max-width: 550px) {
  .navbar .navbar-top {
    display: block;
  }
  
  .navbar .navbar-top .contacts {
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .navbar .navbar-top .socials {
    display: none;
  }

  .normal-nav {
    display: none;
  }
  
  .mobile-nav {
    display: block;
  }
}



