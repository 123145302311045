
.Contact {
  padding: 5rem 1rem;
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
}

.Contact .ContactDetails {
  background: url('../../assets/office.jpg');
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 20px rgba(128, 128, 128, 0.6);
  border-radius: 16px 0 0 16px;
  color: white;
  padding: 2rem;
}

.Contact .ContactForm {
  background: white;
  box-shadow: 0 0 20px rgba(128, 128, 128, 0.6);
  padding: 5rem 1rem;
  border-radius: 0 16px 16px 0;
}

.Contact form {
  max-width: var(--max-width);
  margin: 0 auto;
}


.Contact .input {
  height: 40px;
  width: 100%;
  padding-left: 0.5rem;
  margin-top: 1rem;
  background: transparent;
  border: 1px solid black;
}

.Contact .text-area {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
}

.Contact .btn {
  height: 40px;
  width: 40%;
  font-size: 0.9rem;
  outline: 0;
  border: 2px solid transparent;
  background: var(--blue);
  color: white;
  cursor: pointer;
  margin: 5% 0%;
  margin-bottom: 0;
  transition: background 0.5s ease-in-out, width 0.5s ease-in-out;
}






















@media (max-width: 900px) {
  .Contact h2 {
    font-size: 3em;
  }
}




@media (max-width: 700px) {
  .Contact {
    grid-template-columns: 100%;
  }
  
  .Contact .ContactDetails {
    border-radius: 0;
  }

  
  .Contact .ContactForm {
    border-radius: 0;
  }

}



@media (max-width: 600px) {
  .Contact .btn {
    width: 100%;
  }

  .Contact aside {
    flex-direction: column;
  }
  
  .Contact .input {
    width: 100%;
  }
}