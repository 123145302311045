.home .core-features {
  max-width: var(--max-width);
  margin: 0 auto;
  margin-top: 10rem;
  position: relative;
}


.home .core-features .tall-image img {
  width: 50%;
}


.home .core-features .content {
  position: absolute;
  top: 50%;
  right: 0;
  width: 70%;
  padding: 4rem;
  transform: translateY(-50%);
  background: white;
  box-shadow: 20px 10px 30px rgba(188, 188, 188, 0.6);
  border-radius: 6px;
}

.home .core-features .content .tag {
  color: var(--yellow);
  font-weight: 700;
  letter-spacing: 8px;
  margin-bottom: 1rem;
}

.home .core-features .content .title {
  font-size: 2.5rem;
  max-width: 600px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.home .core-features .content .msg {
  margin-bottom: 1rem;
  line-height: 25px;
}



.home .core-features .twin-images {
  display: flex;
  gap: 20px;
  margin-bottom: 2rem;
  /* flex-wrap: nowrap; */
}

.home .core-features .twin-images img {
  width: 100%;
}












/* Why Choose us */

.home .why-choose-us-container {
  margin-top: 7rem;
  background-image: url('../../assets/office3.jpg');
  background-color: rgba(0, 0, 0, 0.6);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  padding: 7rem 0;
}

.home .why-choose-us {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
}

.home .why-choose-us .tag {
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
}

.home .why-choose-us .container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 32% 32% 32%;
}

.home .why-choose-us .single-reason {
  padding: 1rem;
  background: white;
  color: var(--blue);
  border-radius: 6px;
  transition: 0.3s ease;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
}

.home .why-choose-us .single-reason .title {
  margin-bottom: 1rem;
}

.home .why-choose-us .single-reason .msg {
  font-size: 1rem;
}













/* Services */
.home .services {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  margin-top: 7rem;
}

.home .services .tag {
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: var(--blue)
}

.home .services .container {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 49% 49%;
  gap: 2%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.home .services .single-reason {
  padding: 1rem;
  background: var(--blue);
  color: white;
  box-shadow: 0 0 25px rgba(200, 200, 200, 0.7);
  border-radius: 6px;
  transition: 0.3s ease;
  /* width: 48%; */
}

.home .services .single-reason .title {
  margin-bottom: 1rem;
}
















/* Packages */

.home .packages {
  max-width: var(--max-width);
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 7rem;
}

.home .packages .tag {
  text-align: center;
  letter-spacing: 10px;
  color: var(--yellow);
  font-weight: 700;
  margin-bottom: 2rem;
}

.home .packages .title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.home .packages .packages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.home .packages .packages-container .package {
  width: 31%;
  box-shadow: 0 0 30px rgba(203, 203, 203, 0.6);
  transition: 0.3s ease;
}

.home .packages .packages-container .package:hover {
  transform: scale(1.02);
}

.home .packages .packages-container .package img {
  width: 100%;
}

.home .packages .packages-container .package .content {
  padding: 2rem;
  background: white;
}

.home .packages .packages-container .package .content .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.home .packages .packages-container .package .content .info p {
  font-size: 0.9rem;
  color: var(--yellow)
}

.home .packages .packages-container .package .content h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.home .packages .packages-container .package .content .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(206, 206, 206);
  padding-top: 1rem;
}









/* Guides */
.home .guides {
  max-width: var(--max-width);
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 10rem;
}


.home .guides .tag {
  text-align: center;
  letter-spacing: 10px;
  color: var(--yellow);
  font-weight: 700;
  margin-bottom: 2rem;
}

.home .guides .title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.home .guides .guides-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: space-around;
}

.home .guides .guides-container .guide {
  width: 40%;
  box-shadow: 0 0 30px rgba(203, 203, 203, 0.6);
  transition: 0.3s ease;
  padding: 1rem;
}

.home .guides .guides-container .guide img {
  /* width: 100%; */
  height: 200px;
  width: 200px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 50%;
}

.home .guides .guides-container .guide .content {
  padding: 2rem;
  background: white;
}


.home .guides .guides-container .guide .content {
  text-align: center;
}

.home .guides .guides-container .guide .content p:first-of-type {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}






/* Testimonials */
.home .testimonials {
  max-width: var(--max-width);
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 10rem;
}


.home .testimonials .tag {
  text-align: center;
  letter-spacing: 10px;
  color: var(--blue);
  font-weight: 700;
  margin-bottom: 2rem;
}

.home .testimonials .title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--blue)
}

.home .testimonials .testimonials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.home .testimonials .testimonials-container .testimonial {
  width: 31%;
  box-shadow: 0 0 30px rgba(203, 203, 203, 0.6);
  transition: 0.3s ease;
  padding-top: 4rem;
  position: relative;
  margin-top: 4rem;
  border-radius: 6px;
}

.home .testimonials .testimonials-container .testimonial img {
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home .testimonials .testimonials-container .testimonial .content {
  padding: 2rem;
  background: white;
}


.home .testimonials .testimonials-container .testimonial .content {
  text-align: center;
}

.home .testimonials .testimonials-container .testimonial .content p:first-of-type {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.home .testimonials .testimonials-container .testimonial .content p:last-of-type {
  margin-top: 2rem;
  color: rgb(152, 152, 152);
}








@media (max-width: 900px) {
  .home .why-choose-us .container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1%;
  }
}





@media (max-width: 800px) {
  .home .packages .packages-container {
    flex-direction: column;
  }

  .home .packages .packages-container .package {
    width: 320px;
    margin: 0 auto;
  }

  .home .packages .packages-container .package:hover {
    transform: scale(1);
  }
}





@media (max-width: 650px) {
  .home .core-features {
    margin-top: 5rem;
    padding: 1rem;
  }

  .home .core-features .tall-image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .home .core-features .content {
    position: relative;
    top: 0%;
    right: 0;
    width: 100%;
    padding: 2rem;
    transform: translateY(0%);
  }

  .home .why-choose-us-container {
    margin-top: 15rem;
  }

  .home .why-choose-us .container {
    grid-template-columns: 100%;
  }
  
  .home .guides .guides-container {
    flex-direction: column;
  }

  .home .guides .guides-container .guide {
    width: 100%;
    margin: 0 auto;
  }

  .home .testimonials .testimonials-container {
    flex-direction: column;
  }

  .home .testimonials .testimonials-container .testimonial {
    width: 320px;
    margin: 0 auto;
  }

  .home .testimonials .testimonials-container .testimonial img {
    position: relative;
    top: 0rem;
  }

  .home .core-features .content .title {
    font-size: 2rem;
  }

  .home .services .tag {
    font-size: 2rem;
  }

  .home .packages .title {
    font-size: 2rem;
  }

  .home .guides .title {
    font-size: 2rem;
  }

  .home .guides .title {
    font-size: 2rem;
  }

  .home .testimonials .title {
    font-size: 2rem;
  }

}







@media (max-width: 500px) {
  .home .services .container {
    grid-template-columns: 100%;
  }
  
  .home .services .single-reason {
    width: 100%;
  }
}