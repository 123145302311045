.create-blog {
  max-width: var(--max-width);
  margin: 0 auto;
  margin-top: 5rem;
}


.login {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 2rem;
  box-shadow: 0 0 20px rgb(179, 179, 179);
  border-radius: 6px;
}

.create-blog input {
  width: 100%;
  height: 40px;
  border: 1px solid black;
  border-radius: 6px;
  background: transparent;
  padding-left: 1rem;
  font-size: 1.05rem;
}

.create-blog textarea {
  width: 100%;
  /* height: 40px; */
  border: 1px solid black;
  border-radius: 6px;
  background: transparent;
  padding: 1rem;
  font-size: 1.05rem;
}

.create-blog p {
  margin-top: 1rem;
}

.create-blog button {
  height: 40px;
  width: 100%;
  background: var(--blue);
  color: white;
  border: 0;
  cursor: pointer;
}

.create-blog .error-message {
  color: red;
}