@font-face {
  font-family: inter;
  src: url(../src/assets/fonts/inter.ttf);
}

@font-face {
  font-family: montserrat;
  src: url(../src/assets/fonts/montserrat.ttf);
}

@font-face {
  font-family: overpass;
  src: url(../src/assets/fonts/overpass.ttf);
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: 'montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1.1rem;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}


:root {
  --max-width: 1170px;
  --blue: #00446e;
  /* --blue: #0d73b1; */
  --yellow: #ebc11d;
}