.blog-container {
  max-width: var(--max-width);
  margin: 0 auto;
  margin-top: 5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.blog-container a {
  text-decoration: none;
  color: inherit;
}


.skeleton {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border-left: 10px solid var(--blue);
  border-right: 10px solid var(--blue);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  animation: skeleton infinite 1s ease;
}

@keyframes skeleton {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg)
  }
}