.news main {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
}

.news main img {
  max-width: 400px;
  width: 100%;
}

.news main p {
  padding: 1rem 0;
}

.news main ul {
  padding: 1rem 3rem;
}

.news main li {
  padding: 0.5rem;
}

.news video {
  width: 100%;
}






@media (max-width: 500px) {
  .news main ul {
    padding: 0.2rem 2rem;
  }
}