.all-blogs-container {
  max-width: var(--max-width);
  margin: 0 auto;
  margin-top: 5rem;
  padding: 1rem;
}

.all-blogs-container .title {
  margin-bottom: 2rem;
  border: 1px solid black;
  cursor: pointer;
  display: inline-block;
  padding: 1rem;
}

.list-blog {
  background: var(--orange);
  margin-top: 0.5rem;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}

.list-blog div:first-of-type {
  display: flex;
  align-items: center;
  gap: 20px;
}

.list-blog div:last-of-type {
  font-size: 1.5rem;
  cursor: pointer;
}

.list-blog .id {
  background: black;
  color: white;
  padding: 1rem 1rem;
}


.all-blogs-container .delete-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-blogs-container .delete-loader div {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border-left: 10px solid white;
  border-right: 10px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  animation: rotate infinite 0.5s ease;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg)
  }
}