.footer {
  padding: 2%;
  background: var(--blue);
  color: white;
  margin-top: 5rem;
}

.footer h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--yellow)
}

.footer a {
  text-decoration: none;
  color: inherit
}

.footer .title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 7px;
  color: white;
  font-weight: bold;
}

.footer .follow-us {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer .contact {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .useful-links li {
  list-style: none;
  padding: 0.5rem 0;
  cursor: pointer;
}

.footer .first-section {
  display: grid;
  grid-template-columns: 50% 50%;
}

.footer .first-section aside:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.footer .second-section {
  /* margin-top: 2rem; */
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid rgb(180, 180, 180); */
  padding-top: 1rem;
}

.footer .open-login {
  font-size: 2rem;
  color: var(--yellow);
  cursor: pointer;
}




@media (max-width: 650px) {
  .footer {
    padding: 1rem;
  }

  .footer .first-section {
    flex-direction: column;
  }

  .footer .first-section div {
    width: 100%;
  }

}

@media (max-width: 500px) {
  .footer .second-section {
    flex-direction: column;
  }

  .footer .first-section {
    display: grid;
    grid-template-columns: 100%;
  }

  .footer .first-section aside:nth-child(2) {
    justify-content: flex-start;
  }

  .footer .first-section p:nth-child(2) {
    display: none;
  }
}
