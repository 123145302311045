/* .mobile-navbar {
  position: relative;
} */

.mobile-navbar .drawer {
  position: absolute;
  background: white;
  left: 0;
  top: -60%;
  width: 100%;
  z-index: 6;
}

.mobile-navbar .open-drawer {
  animation: open-drawer 0.4s ease 1 forwards;
}

@keyframes open-drawer {
  from {
    top: -60%;
  }
  to {
    top: 0%;
  }
}

.mobile-navbar .close-drawer {
  animation: close-drawer 0.4s ease 1 forwards;
}

@keyframes close-drawer {
  from {
    top: 0%;
  }
  to {
    top: -60%;
  }
}

.mobile-navbar .drawer li {
  padding: 1rem;
  outline: 1px solid rgb(207, 207, 207);
}

.mobile-navbar .close {
  display: block;
  font-size: 2.5rem;
  text-align: right;
  padding: 1rem;
  cursor: pointer;
}

.mobile-navbar .open {
  display: block;
  font-size: 2.5rem;
  text-align: right;
  cursor: pointer;
  color: white;
}