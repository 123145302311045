.hero {
  height: 600px;
  width: 100%;
  position: relative;
  color: white
}

.hero .hero-image {
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.hero .content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: var(--max-width);
  width: 100%;
  height: 600px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 3;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

.hero .content h1 {
  font-size: 4rem;
  max-width: 800px;
  color: var(--yellow)
  /* text-align: center; */
}





@media (max-width: 700px) {

  .hero .content h1 {
    font-size: 2rem;
  }

  .hero {
    height: 400px;
  }
  
  .hero .hero-image {
    height: 400px;
  }
  
  .hero .overlay {
    height: 400px;
  }
  
  .hero .content {
    height: 400px;
    padding: 0 1rem;
  }

}