.blog-component {
  max-width: 300px;
  width: 310px;
  box-shadow: 0 0 20px rgb(203, 203, 203);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease;
}

.blog-component:hover {
  transform: scale(1.02);
}

.blog-component img {
  width: 100%;
  border-radius: 6px 6px 0 0;
  height: 200px;
  object-fit: cover;
}

.blog-component aside {
  padding: 1rem;
  background-color: white;
}