.button {
  /* background: var(--blue); */
  padding: 1.2rem 3rem;
  border-radius: 6px;
  cursor: pointer;
  max-width: 200px;
  background: orange;
}

.button-blue {
  /* background: var(--blue); */

}

.button-yellow {
  /* background: var(--yellow); */
  color: black;
  font-weight: bold;
}

.button-container a {
  text-decoration: none;
  color: white;
}