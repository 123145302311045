.about main {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1rem;
}

.about .title {
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.about .intro {
  margin-bottom: 2rem;
}

.about main .big-tag {
  color: var(--yellow);
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 1rem 0;
  margin-top: 3rem;
}

.about main .small-tag {
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

.about main .twin-images {
  margin-top: 2rem;
  display: flex;
  gap: 30px;
}

.about main .twin-images img {
  max-width: 400px;
  width: 100%;
}



.about .founder {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
}

.about .founder p {
  padding: 1rem;
  line-height: 24px;
}

.about .founder img {
  width: 100%;
}






@media (max-width: 800px) {
  .about .founder {
    grid-template-columns: 100%;
  }

  .about .founder p {
    padding: 0rem;
  }
}


@media (max-width: 650px) {
  .about main .twin-images {
    flex-direction: column;
  }
}


@media (max-width: 480px) {
  .about .title, .about main .big-tag{
    font-size: 1.5rem;
  }
}